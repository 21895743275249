<template>
    <v-row class="bgc2 padMobile" style="padding: 20px 60px;">
            <v-col cols="12" md="12" class="alinhadoM">
                <h1 style="font-family: GPMetro light; color: #EF7C00; font-size: 35px;">Benefícios</h1>
            </v-col>
            <v-col cols="6" md="3">
                <div style="background-color: #f6a300db; border-radius: 8px; padding: 20px; height: 300px; min-height: 300px;"
                    class="padCardMob align-center justify-center d-flex flex-column">
                    <img src="../assets/icon1.webp" contain width="100" height="100" />
                    <p
                        style="min-height:90px; text-align: center; margin-top: 20px; margin-bottom: 0px !important; font-family: GPMetro light; color: #fff; font-size: 20px;">
                        Consultas a partir de <span style="color: #7B5815"> R$20,00 </span></p>
                </div>
            </v-col>

            <v-col cols="6" md="3">
                <div style="background-color: #f6a300db; border-radius: 8px; padding: 20px; height: 300px; min-height: 300px;"
                    class="padCardMob align-center justify-center d-flex flex-column">
                    <img src="../assets/70Desc.webp" contain width="100" height="100" />
                    <p
                        style="text-align: center; margin-top: 20px; margin-bottom: 0px !important; font-family: GPMetro light; color: #fff; font-size: 20px;">
                        Até <span style="color: #7B5815"> 70% </span> de desconto em
                        exames <span style="color: #7B5815"> laboratoriais </span> e
                        de <span style="color: #7B5815"> imagem</span>
                    </p>
                </div>
            </v-col>

            <v-col cols="6" md="3">
                <div style="background-color: #f6a300db; border-radius: 8px; padding: 20px; height: 300px; min-height: 300px;"
                    class="padCardMob align-center justify-center d-flex flex-column">
                    <img src="../assets/icon3.webp" contain width="100" height="100" />
                    <p
                        style="text-align: center; margin-top: 20px; margin-bottom: 0px !important; font-family: GPMetro light; color: #fff; font-size: 20px;">
                        Procedimentos
                        <span style="color: #7B5815"> odontológicos </span> com
                        até <span style="color: #7B5815"> 50% </span> de desconto
                        R$20,00
                    </p>
                </div>
            </v-col>

            <v-col cols="6" md="3">
                <div style="background-color: #f6a300db; border-radius: 8px; padding: 20px; height: 300px; min-height: 300px;"
                    class="padCardMob align-center justify-center d-flex flex-column">
                    <img src="../assets/icon4.webp" contain width="100" height="100" />
                    <p
                        style="text-align: center; margin-top: 20px; margin-bottom: 0px !important; font-family: GPMetro light; color: #fff; font-size: 20px;">
                        Adquira seu cartão por pouco mais de <span style="color: #7B5815"> R$1,00 por dia </span>
                    </p>
                </div>
            </v-col>
    </v-row>

</template>
<style scoped>
    span { 
        font-weight:bold;
    }
</style>
<script>

export default {
    name:"beneficio",
    data:() =>  ({
        
    }),
}
</script>
