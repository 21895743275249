<template>
    <v-container fluid>
        <!-- <v-app-bar app color="#fff" dark v-if="!isHome" justify="center" align="center"
            style="padding: 4px 60px; height: 100px; background-color: #fff; "
            class="justify-center alignMobile d-flex flex-column padMobile">
            <v-row style="width: 100%" >
                <v-col cols="12" md="12" class="align-self-start ">
                    <div class="d-flex align-center justify-center">
                        <router-link :to="'/' + $route.params.cidade + '/cafezinho'" class="d-flex justify-center">
                            <v-img alt="Vuetify Name" class="shrink mt-1 mobileSize" contain min-width="50%"
                                src="../assets/logo.webp" width="50%" />
                        </router-link>
                    </div>
                </v-col>
            </v-row>
        </v-app-bar> -->
        <v-row style="background-color: #ECECEC3F;">
            <v-col cols="12" md="6" class="d-flex">
                <router-link :to="'/' + $route.params.cidade + '/novaLp'" class="d-flex justify-center">
                    <v-img alt="Cartão Beneficiar" class="mobileSize" contain src="../assets/logo.webp" style="max-width:200px; margin-left:50px" />
                </router-link>
            </v-col>
            <v-col cols="12" md="6" class="align-center d-flex justifyContentBotão" style="justify-content:right">
                <a href="tel:08007292072">
                    <v-btn style="
                    background-color:#3D866E;
                    font-weight:800;
                    color:#fff;
                    font-size:20px;
                    border-radius:30px;
                    padding:5px 20px">
                        0800 729 2072
                    </v-btn>
                </a>
            </v-col>
        </v-row>

        <v-row style="background-color: #ECECEC3F;">
            <v-col cols="12" md="12" class="align-center justify-center d-flex flex-column">
                <h1
                class="fontSizeMob"
                style="font-size:80px;
                color:#09564C;
                font-weight:800;
                text-align:center;
                margin-left:60px;
                margin-bottom:50px;
                line-height: 1;
                margin-top:30px;">
                <span style="font-size:30px; color:#000">Sua família merece</span><br>
                QUALIDADE DE VIDA</h1>

                <v-card
                class="align-center justify-center widHeightDiv"
                style="
                max-width:900px;
                width:900px;
                max-height:500px;
                background-color:#F6F6F6;
                border-radius:30px;">
                    <v-row style="background-color: #0B7849; margin:-2px">
                        <v-col cols="6" md="6" class="align-center justify-center d-flex">
                            <h1 style="font-size:30px; color:#fff">R$32</h1>
                        </v-col>
                        <v-col cols="6" md="6" class="align-center justify-center d-flex">
                            <h1 style="font-size:30px; color:#fff">R$45</h1>
                        </v-col>
                    </v-row>
                    <v-row style="">
                        <v-col cols="6" md="6" class="align-center d-flex flex-column" style="">
                            <div class="align-content-left">
                            <p style="font-size:20px; color:#000">
                            • Cardiologista</p>
                            <p style="font-size:20px; color:#000">
                            • Dermatologista</p>
                            <p style="font-size:20px; color:#000">
                            • Geriatra</p>
                            <p style="font-size:20px; color:#000">
                            • Ginecologista</p>
                            <p style="font-size:20px; color:#000">
                            • Ortopedista</p>
                            <p style="font-size:20px; color:#000">
                            • Pediatra</p>
                            <p style="font-size:20px; color:#000">
                            • +20 especialidades</p>
                            </div>
                        </v-col>
                        <v-col cols="6" md="6" class="align-center d-flex flex-column">
                            <div class="align-content-left">
                            <p style="font-size:20px; color:#000">
                            • Oftamologista</p>
                            <p style="font-size:20px; color:#000">
                            • Neurologista</p>
                            <p style="font-size:20px; color:#000">
                            • Psiquiatra</p>
                            </div>
                            <v-img v-if="(intBreakPoint > 2)" contain style="max-width:150px; opacity:0.3;
                              position:absolute; right:0%; bottom:-14%" src="../assets/efeitin.webp"></v-img>
                        </v-col>
                    </v-row>
                    <v-row style="background-color: #0B7849; margin:-2px">
                        <v-col cols="12" md="12" class="align-center justify-center d-flex">
                            <h1 style="font-size:30px; color:#fff">
                                • Aparelho Ortodôntico + documentação Grátis
                            </h1>
                        </v-col>
                    </v-row>
                </v-card>
                <v-btn
                    class="btnMb"
                    @click="(dialog=true)"
                    style="
                    background-color:#EF7C00;
                    color:#fff;
                    font-weight:800;
                    padding:5px 15px;
                    margin-top:20px;
                    text-transform: none;
                    font-size:30px;
                    border-radius: 30px;"
                    >Quero cuidar da <br v-if="(intBreakPoint < 2 )"> minha família</v-btn>
            </v-col>
        </v-row>
        <v-row class="" style="background-color: #ECECEC3F; height:80vh">
            <v-col v-if="intBreakPoint > 2" cols="12" md="6" style="position:absolute">
                <v-img contain style="
                max-width:800px;
                left: 150%;
                top:-150%;"
                src="../assets/novaLp/bolona.png"></v-img>
            </v-col>
            <v-col cols="12" md="12" class="align-center justify-center d-flex flex-column">
                <v-img class="wdImgMobile align-center justify-center d-flex" contain style="max-width:1000px; width:1000px" src="../assets/novaLp/cartao.png">
                <h1
                class="fontImgMobile"
                style="font-size:40px;
                color:#fff;
                font-weight:800;
                margin-left:60px;
                margin-top:60px;">O CARTÃO DE <br> BENEFÍCIOS para <br>toda sua família:</h1>
                </v-img>
            </v-col>
            
        </v-row>

        <v-row style="background-color: #ECECEC3F;">
            <v-col cols="12" md="6" class="align-center justify-center d-flex flex-column distanciaEntreCols">
                <div>
                    <div class="d-flex align-center" style="margin-bottom:20px">
                        <v-icon style="
                        color:#007845;
                        margin-right:5px;">
                        mdi-check-bold
                        </v-icon>
                        <p style="font-size:20px; color:#000; margin-bottom:0px">
                        Consultas médicas e Odontológicas
                        </p>
                    </div>
                    <div class="d-flex align-center" style="margin-bottom:20px">
                        <v-icon style="
                        color:#007845;
                        margin-right:5px;">
                        mdi-check-bold
                        </v-icon>
                        <p style="font-size:20px; color:#000; margin-bottom:0px">
                        Exames Laboratoriais
                        </p>
                    </div>
                    <div class="d-flex align-center" style="margin-bottom:20px">
                        <v-icon style="
                        color:#007845;
                        margin-right:5px;">
                        mdi-check-bold
                        </v-icon>
                        <p style="font-size:20px; color:#000; margin-bottom:0px">
                        Fisioterapia
                        </p>
                    </div>
                    <div class="d-flex align-center" style="margin-bottom:20px">
                        <v-icon style="
                        color:#007845;
                        margin-right:5px;">
                        mdi-check-bold
                        </v-icon>
                        <p style="font-size:20px; color:#000; margin-bottom:0px">
                        Estética
                        </p>
                    </div>
                </div>
            </v-col>

            <v-col cols="12" md="6" class="justify-center d-flex flex-column distanciaEntreCols">
                <div class="d-flex align-center" style="margin-bottom:20px">
                    <v-icon style="
                    color:#007845;
                    margin-right:5px;">
                    mdi-check-bold
                    </v-icon>
                    <p style="font-size:20px; color:#000; margin-bottom:0px">
                    Seguro de vida
                    </p>
                </div>
                <div class="d-flex align-center" style="margin-bottom:20px">
                    <v-icon style="
                    color:#007845;
                    margin-right:5px;">
                    mdi-check-bold
                    </v-icon>
                    <p style="font-size:20px; color:#000; margin-bottom:0px">
                    Assistência Funeral
                    </p>
                </div>
                <div class="d-flex align-center" style="margin-bottom:20px">
                    <v-icon style="
                    color:#007845;
                    margin-right:5px;">
                    mdi-check-bold
                    </v-icon>
                    <p style="font-size:20px; color:#000; margin-bottom:0px">
                    Farmácias, Escolas, Academias e muito mais!
                    </p>
                </div>
                <div class="d-flex align-center" style="margin-bottom:20px">
                    <v-btn
                    @click="(dialog=true)"
                    style="
                    background-color:#EF7C00;
                    color:#fff;
                    font-weight:800;
                    padding:5px 15px;
                    text-transform: none;
                    font-size:20px;
                    border-radius: 30px;"
                    >Quero meu cartão</v-btn>
                </div>
            </v-col>
        </v-row>

        <v-row class="bgc2" style="padding:100px 0px; background-color: #ECECEC3F;">
            <!-- <v-col v-if="intBreakPoint > 2" cols="12" md="6" style="position:absolute">
                <v-img contain style="
                max-width:800px;
                left: 120%;
                opacity:0.2;
                top:165px;"
                src="../assets/novaLp/bolona.png"></v-img>
            </v-col> -->
            <v-col cols="12" md="6" class="alignLeftMobile justify-center flex-column d-flex" style="align-items: center">
                <div style="justify-content: left">
                <h1 class="fontWhite" style="color:#000; font-size:35px; font-weight:500; line-height:1">
                <span class="fontWhite" style="font-weight:800; color:#09564C">Mensalidade</span>
                para <br>toda a família por</h1>
                <h1
                class="textAlignLeft fontWhite" 
                style="color:#09564C; font-size: 25px">R$ <span style="font-size:120px;">34</span>,90</h1>
                <div class="d-flex flex-column" style="">
                    <p class="fontWhite" style="font-size:25px; color:#000; margin-bottom:10px; font-weight: 700; line-height: 1;">
                        • Peça seu cartão e já <br>agende sua consulta
                    </p>
                    <!-- <p class="fontWhite" style="font-size:20px; color:#000; margin-bottom:10px; font-weight: 700">
                        • Peça seu cartão e já agende sua consulta
                    </p> 
                    <p class="fontWhite" style="font-size:20px; color:#000; margin-bottom:10px; font-weight: 700">
                        • Sem carência, peça agora e já agende sua consulta
                    </p> 
                    <p class="fontWhite" style="font-size:20px; color:#000; margin-bottom:10px; font-weight: 700">
                        • Não tem carência, peça o seu aproveite todos os benefícios
                    </p> -->
                </div>
                    <v-btn
                    @click="(dialog=true)"
                    style="
                    background-color:#EF7C00;
                    color:#fff;
                    font-weight:500;
                    text-transform: none;
                    padding:5px 15px;
                    font-size:20px;
                    border-radius: 30px;"
                    >Pedir meu cartão</v-btn>
                </div>
            </v-col>
        </v-row>

        <!-- <v-row style="padding:30px 0px; background-color: #ECECEC3F;">
            <v-col cols="12" md="12" class="align-center justify-center d-flex flex-column" style="">
                <div class="borda paddingBordasDiv"
                style="border:3px solid #09564c; border-radius:30px 30px 0px 0px; padding:0px 60px;
                border-bottom: none;">
                    <div style="padding:5px 15px; border-radius: 30px; background-color:#09564C; margin-top:-20px;">
                        <h1 style="
                        color:#fff;
                        font-size:30px;
                        font-weight:800;">Sua família merece qualidade de vida!</h1>
                    </div>
                    <div class="d-flex flex-column">
                        <div class="d-flex flexMb">
                            <v-col cols="12" md="6" class="align-center justify-center d-flex flex-column" style="margin-right:50px">
                                <h1
                                class="textAlignLeft"
                                style="color:#000; font-weight:800; font-size:30px; text-align:center">
                                    Clínico Geral <br>
                                <span style="color:#09564C; font-size:70px; line-height: 1;">Grátis</span><br>
                                <span style="font-size:18px; line-height: 1;"> *6 meses</span></h1>
                            </v-col>
                            <v-col cols="12" md="6" class="align-center justify-center d-flex flex-column">
                                <h1
                                class="textAlignLeft"
                                style="color:#000; font-weight:800; font-size:30px; text-align:center; line-height: 1;">
                                    Consultas a <br>partir de <br>
                                <span style="color:#09564C; font-size:80px; line-height: 1;">20</span>
                                <span style="color:#09564C">,00</span></h1>
                            </v-col>
                        </div>
                            <v-col cols="12" md="12" class="align-center justify-center d-flex flex-column">
                                <h1
                                class="textAlignLeft tamanhoFont"
                                style="color:#000; font-weight:800; font-size:30px; text-align:center">
                                    Nunca foi tão fácil oferecer saúde <br v-if="(intBreakPoint > 2)">
                                    de qualidade para sua família
                                </h1>
                            </v-col>
                    </div>
                </div>
            </v-col>
        </v-row> -->

        <v-row class="colorFooter" justify="center" align="center">
            <v-col cols="12" md="12" class="d-flex align-center justify-center">
                <v-img style="max-width:50px; margin-right:10px" contain src="../assets/abareBranco.png"></v-img>
                <v-img contain style="max-width:150px" src="../assets/logoBranco.webp"></v-img>
            </v-col>
            <!-- <v-col cols="12" md="6" class="d-flex align-center" style="justify-content: right">
                <v-img style="max-width:50px" contain src="../assets/abareBranco.png"></v-img>
            </v-col>
            <v-col cols="12" md="6" class="d-flex align-center" >
                <v-img contain style="max-width:150px" src="../assets/logoBranco.webp"></v-img>
            </v-col> -->
        </v-row>


        <v-dialog v-model="dialog" max-width="800" >
            <v-card class="bgcDialog" style="margin:0px !important; padding: 5px !important; height: 100% !important" >
            <v-row style="background-color:#09564C; padding:20px 20px; margin:10px; border-radius:10px">
                <v-col cols="6" md="6" class="d-flex">
                    <v-img contain style="max-width:200px" class="wdLogo" src="../assets/logoBranco.webp"></v-img>
                    
                </v-col>
                <v-col cols="6" md="6" class="pdlfBtn" style="padding-left:320px">
                    <v-btn icon @click="dialog = false">
                    <v-icon style="color:#fff">
                        mdi-close
                    </v-icon>
                </v-btn>
                </v-col>
            </v-row>
            <v-row style="margin:10px">
                <v-col cols="12" md="8" style="background-color:#fff; margin:0px; border-radius:20px">
                    <p style="font-size:17px; color: 800; margin-bottom:0px ">
                    Olá, complete os campos abaixo e entre em contato conosco!
                    </p>
                </v-col>
            </v-row>
            <v-row style="margin:10px">
                <v-col cols="12" md="6"></v-col>
                <v-col cols="12" md="6" class="justify-center d-flex flex-column">
                    <form :id="end.form" ref="formulario" action="/novaLp/obrigado">
                    <v-text-field height="50px" background-color="#E7FFE7" placeholder="Nome completo" v-model="contato.nome" outlined style="border-radius:5px;"></v-text-field>
                    <input type="text" required placeholder="E-mail" hidden name="email" v-model="computedEmail" />
                    <v-text-field height="50px" background-color="#E7FFE7" v-mask="'(##) #####-####'" placeholder="Whatsapp" v-model="contato.telefone" outlined style="border-radius:5px"></v-text-field>
                    <button :disabled="loading" class="pdBtn" type="submit" @click="enviarFormulario" style="background-color:#09564C; padding:0px 100px; color:#fff; font-weight:800; text-transform:none; font-size:18px">Iniciar atendimento</button>
                </form>
                </v-col>
            </v-row>
        </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
// import beneficios from '../components/beneficios.vue';
export default {
//   components: { beneficios },
//     name: "App",
    data: () => ({
        end: {},
        loading: false,
        dialog: false,
        contato: { nome: "", telefone: "" },
    }),

    methods: {
        goto(refName) {
            var element = this.$refs[refName];
            var top = element.offsetTop;

            window.scrollTo(0, top);
        },
        enviarFormulario(e) {
            e.preventDefault()
            if ((this.contato.nome === "")) { 
                alert("Por favor preencha todos os campos")
                this.loading = false
                return;
            }
            else if ((this.contato.telefone === "")){
                alert("Por favor preencha todos os campos")
                this.loading = false
                return;
            }
            else{
                this.abrirWhatsapp();
                this.dialog = false;
            }
            this.loading = true;
            fetch("https://gestao.abare.cloud/items/leads", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                },
                body: JSON.stringify({ id_formulario: this.end.id, lead: this.contato }),
            })
                .then((response) => response.text())
                .then((data) => {
                    window.console.log(data);
                    // alert("contato enviado com sucesso");
                    this.snackbar = true;
                    this.enviaRdstation();
                });
        },
        enviaRdstation() { 
            fetch("https://abaremarketing.com/phpCartaoBe.php", {
                method: "POST",
                mode: "no-cors",
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                },
                body: JSON.stringify(
                   {nome: this.contato.nome, telefone: this.contato.telefone, email: this.computedEmail, id_funil: this.end.idFunil} 
                ),
            })
                .then((response) => response.text())
                .then((data) => {
                    window.console.log(data);
                    this.abrirObrigado();
                    this.$refs.formulario.submit();
                });
        },

        abrirObrigado(){
            this.$router.push('/'+this.$route.params.cidade +'/novaLp/obrigado')
            window.location.reload();

        },

        abrirWhatsapp(){
            window.open(this.end.whats)
        }
        
    },

    mounted() {
        window.scrollTo({
            top: 50,
            left: 0,
            behavior: "smooth",
        });

        let city = this.$route.params.cidade
        if (city == "sao-jose-dos-campos"){
            this.end = {
                iframe: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3667.5089012140484!2d-45.8851622!3d-23.1881175!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94cc4a15f76c83cf%3A0xf521eb334f265620!2sAv.%20Dr.%20Jo%C3%A3o%20Guilhermino%2C%20101%20-%20Centro%2C%20S%C3%A3o%20Jos%C3%A9%20dos%20Campos%20-%20SP%2C%2012210-131!5e0!3m2!1spt-BR!2sbr!4v1660910694402!5m2!1spt-BR!2sbr",
                 goggle: "https://www.google.com/maps/place/Av.+Dr.+Jo%C3%A3o+Guilhermino,+101+-+Centro,+S%C3%A3o+Jos%C3%A9+dos+Campos+-+SP,+12210-131/@-23.1881175,-45.8851622,17z/data=!3m1!4b1!4m5!3m4!1s0x94cc4a15f76c83cf:0xf521eb334f265620!8m2!3d-23.1881175!4d-45.8851622",
                 whats: "https://api.whatsapp.com/send?phone=551230420948",
                 id: 93,
                 form: "form_sjc",
                 idFunil: "63874d2c80b188000cd7d869"
           } 
        }
        
         if (city == "sorocaba") {
             this.end = { 
                 iframe: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3658.8703558087204!2d-47.4618539!3d-23.5011787!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94c58abc5cc6cc91%3A0x60bf2b917ee87186!2sR.%20da%20Penha%2C%20835%20-%20Centro%2C%20Sorocaba%20-%20SP%2C%2018010-003!5e0!3m2!1spt-BR!2sbr!4v1660910831690!5m2!1spt-BR!2sbr",
                 goggle: "https://www.google.com/maps/place/R.+da+Penha,+835+-+Centro,+Sorocaba+-+SP,+18010-003/@-23.5011787,-47.4618539,17z/data=!3m1!4b1!4m5!3m4!1s0x94c58abc5cc6cc91:0x60bf2b917ee87186!8m2!3d-23.5011787!4d-47.4618539",
                 whats: "https://api.whatsapp.com/send?phone=551531410960",
                 id: 94,
                 form: "form_sorocaba",
                 idFunil: "63874c85e489a6001f98ae61"
           }
         }
    },

    computed: {
        computedEmail() {
            return `${this.contato.telefone
                .replace("-", "")
                .replace("(", "")
                .replace(")", "")
                .replaceAll(" ", "")}@${this.contato.nome.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replaceAll(" ", "").toLowerCase()}.com.br`;
        },
        isHome() {
            return this.$route.name == "inicial";
        },
        intBreakPoint() {
            switch (this.$vuetify.breakpoint.name) {
                case "xs":
                    return 0;
                case "sm":
                    return 1;
                case "md":
                    return 2;
                case "lg":
                    return 3;
                case "xl":
                    return 4;
                default:
                    return 0;
            }
        },
    },

}
</script>

<style scoped>
@media (min-device-width: 360px) and (max-device-width: 640px) {

    .widHeightDiv{
        max-width:335px !important;
        max-height:700px !important;
        height:auto !important;
        width:auto !important;
    }

    .btnMb{
        font-size:23px !important;
        padding:28px 15px !important;
    }
    .flexMb{
        flex-direction: column;
    }
    .fontImgMobile{
        font-size:19px !important;
        line-height: 1 !important;
        margin-left: 10px !important;
        margin-top: 22px !important;
    }
    .paddingBordasDiv{
        padding: 0px 14px !important;
    }
    .justifyContentBotão{
        justify-content: center !important; 
    }
    .textAlignLeft{
        text-align: left !important;
    }
    .alignLeftMobile{
        align-items: start !important;
    }
    .distanciaEntreCols{
        padding: 0px 12px !important;
    }
    .tamanhoFont{
        font-size: 20px !important;
        line-height: 1 !important;
        font-weight:300 !important;
    }
    .bgc2 {
    background-image: url("../assets/bannerInicial.webp") !important;
    }
    .fontWhite{
        color: #fff !important; 
    }
    .footer{
        margin-top: 0px;
        padding: 10px 10px;
        margin: 0 0px !important;
    }
    .fontSizeMob{
        font-size: 50px !important;
        margin-left:10px !important;
    }

    .wdImgMobile{
        max-width:350px !important;
        width:350px !important;
    }
    
    .pdBtn{
        padding: 0px 50px !important;
    }
    .pdlfBtn{
        padding-left:90px !important;
    }
    .wdLogo{
        max-width: 170px !important
    }
    .bgc1 {
        padding: 62px 0px !important;
    }

    .paddingMobiPt1 {
        padding: 80px 10px;
    }

    .sizeMobile {
        width: 200px !important;
    }

    .disabledMobile {
        display: none !important;
    }

    .heightMob {
        height: auto !important;
    }

    .padMobile {
        padding: 20px 10px !important;
    }

    .padCardMob {
        padding: 10px !important;
    }

    .disabledDesk {
        display: initial !important;
    }
}

.disabledDesk {
    display: none;
}

.bgc2 {
    background-image: url("../assets/novaLp/velho.png");
    background-repeat: repeat;
    background-size: cover;
    /* background-attachment: fixed; */
    background-position: center;
    height:100vh;
}

.bgcDialog {
    background-image: url("../assets/whatsapp.png") !important;
    background-repeat: repeat;
    background-size: cover;
    /* background-attachment: fixed; */
    background-position: center;
}

.bgc1 {
    background-image: url("../assets/bannerCartao2.webp") !important;
    background-repeat: repeat;
    background-size: cover;
    height: 100vh;
    /* background-attachment: fixed; */
    background-position: center;
}

.colorFooter{
    background: linear-gradient(177deg, rgba(59,131,107,1) 9%, rgba(43,105,84,1) 49%);
}
/* .borda{
    border-image: linear-gradient(to bottom,  rgba(59,131,107,1) 9%, rgb(220, 250, 240)) 1 100% !important;
} */
.border {
  border: 10px solid;
  border-image-slice: 1;
  border-width: 5px;
  border-image-source: linear-gradient(to bottom,  rgba(59,131,107,1) 9%, rgb(220, 250, 240)) 1 100% !important;
}
</style>