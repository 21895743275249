<template>
    <v-container fluid>
        <v-app-bar app color="#fff" dark v-if="!isHome" jutify="center" align="center"
            style="padding: 4px 60px; height: 100px; background-color: #fff; "
            class="justify-center alignMobile d-flex flex-column padMobile">
            <v-row style="width: 100%" align="center">
                <v-col cols="12" md="12">
                    <div class="d-flex align-center justify-center">
                        <router-link :to="'/' + $route.params.cidade + '/lp-video'" class="d-flex justify-center">
                            <v-img alt="Vuetify Name" class="shrink mt-1 mobileSize" contain min-width="50%"
                                src="../assets/logo.webp" width="50%" />
                        </router-link>
                    </div>
                </v-col>
            </v-row>
        </v-app-bar>

        <v-row class="justify-center align-center bgc1 heightMob" style="padding: 0 30px;">
            <v-col cols="12" md="7" class="d-flex " style="padding: 20px">
                <video width="100%" autoplay controls height="100%"
                    src="https://abaremarketing.com/media/cartaobeneficiar.mp4"></video>
            </v-col>
            <v-col cols="12" md="5">
                <p style="margin-bottom: 0px !important; font-family: GPMetro light; color: #fff; font-size: 25px;">
                    Chega de perder tempo <br>
                    <span style="font-size: 50px; font-family: GPMetro light; color: #fff;"> na fila do SUS,</span>
                </p>
                <v-row class="d-flex">
                    <v-col cols="12" md="6">
                        <v-img src="../assets/preco1.webp" contain class="sizeMobile"></v-img>
                    </v-col>

                    <v-col cols="12" md="6">
                        <v-img src="../assets/preco3.png" contain class="sizeMobile"></v-img>
                    </v-col>
                </v-row>

               
                    <v-btn  @click="dialog = true" v-if="intBreakPoint < 2" block
                        style="margin-top: 20px; background-color: #EF7C00; color: #fff; box-shadow: none;">Agende
                        sua consulta agora!</v-btn>
                

               
                    <v-btn  @click="dialog = true" v-if="intBreakPoint > 2" block
                        style="margin-top: 20px; background-color: #EF7C00; color: #fff; box-shadow: none;">Agende
                        sua consulta agora mesmo!</v-btn>
                
            </v-col>
        </v-row>

        <beneficios></beneficios>
        <v-row>
            <v-col cols="12" md="12" class="alinhadoM">
               
                    <v-btn  @click="dialog = true" style="margin-top: 20px; background-color: #EF7C00; color: #fff; box-shadow: none;">Agende
                        sua consulta agora mesmo!</v-btn>
                
            </v-col>
        </v-row>

        <v-row class="padMob" justify="center" align="center">
            <a style=" position: absolute; justify-content: center;"
                :href="end.goggle"
                target="_blank">
                <v-btn style="z-index: 99; align-items: center; background-color: #EF7C00; color: #fff; box-shadow: none; font-size: 25px; font-family: 'GPMetro Light';
                    text-transform: initial; padding: 25px;">Como chegar</v-btn>
            </a>
            <v-col cols="12" md="12">
                <div class="alturaFooterGreen"
                    style="background-color: #007845; opacity: 0.6; position: absolute; height: 330px; left: 0px; right: 0px; bottom: 0px;">
                </div>
                <iframe
                    :src="end.iframe"
                    width="100%" height="300" style="border:0;" allowfullscreen="" loading="lazy"
                    referrerpolicy="no-referrer-when-downgrade"></iframe>
            </v-col>
        </v-row>

        <v-dialog v-model="dialog" max-width="800" >
            <v-card class="bgcDialog" style="margin:0px !important; padding: 5px !important; height: 100% !important" >
            <v-row style="background-color:#09564C; padding:20px 20px; margin:10px; border-radius:10px">
                <v-col cols="6" md="6" class="d-flex">
                    <v-img contain style="max-width:200px" class="wdLogo" src="../assets/logoBranco.webp"></v-img>
                    
                </v-col>
                <v-col cols="6" md="6" class="pdlfBtn" style="padding-left:320px">
                    <v-btn icon @click="dialog = false">
                    <v-icon style="color:#fff">
                        mdi-close
                    </v-icon>
                </v-btn>
                </v-col>
            </v-row>
            <v-row style="margin:10px">
                <v-col cols="12" md="8" style="background-color:#fff; margin:0px; border-radius:20px">
                    <p style="font-size:17px; color: 800; margin-bottom:0px ">
                    Olá, complete os campos abaixo e entre em contato conosco!
                    </p>
                </v-col>
            </v-row>
            <v-row style="margin:10px">
                <v-col cols="12" md="6"></v-col>
                <v-col cols="12" md="6" class="justify-center d-flex flex-column">
                    <form :id="end.form" ref="formulario" action="/lp-video/obrigado">
                    <v-text-field height="50px" background-color="#E7FFE7" placeholder="Nome completo" v-model="contato.nome" outlined style="border-radius:5px;"></v-text-field>
                    <input type="text" required placeholder="E-mail" hidden name="email" v-model="computedEmail" />
                    <v-text-field height="50px" background-color="#E7FFE7" v-mask="'(##) #####-####'" placeholder="Whatsapp" v-model="contato.telefone" outlined style="border-radius:5px"></v-text-field>
                    <button type="submit" class="pdBtn" @click="enviarFormulario" style="background-color:#09564C;padding:0px 100px;  color:#fff; font-weight:800; text-transform:none; font-size:18px">Iniciar atendimento</button>
                </form>
                </v-col>
            </v-row>
        </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import beneficios from "../components/beneficios.vue"

export default {
    name: "App",
    components: { beneficios },
    data: () => ({
        end: {},
        dialog: false,
        contato: { nome: "", telefone: "" },
    }),

    methods: {
        goto(refName) {
            var element = this.$refs[refName];
            var top = element.offsetTop;

            window.scrollTo(0, top);
        },
        enviarFormulario(e) {
            e.preventDefault()
            if ((this.contato.nome === "")) { 
                alert("Por favor preencha todos os campos")
                this.loading = false
                return;
            }
            else if ((this.contato.telefone === "")){
                alert("Por favor preencha todos os campos")
                this.loading = false
                return;
            }
            else{
                this.abrirWhatsapp();
                this.dialog = false;
            }
            fetch("https://gestao.abare.cloud/items/leads", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                },
                body: JSON.stringify({ id_formulario: this.end.id, lead: this.contato }),
            })
                .then((response) => response.text())
                .then((data) => {
                    window.console.log(data);
                    // alert("contato enviado com sucesso");
                    this.snackbar = true;
                    this.enviaRdstation();
                });
        },
        enviaRdstation() { 
            fetch("https://abaremarketing.com/phpCartaoBe.php", {
                method: "POST",
                mode: "no-cors",
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                },
                body: JSON.stringify(
                   {nome: this.contato.nome, telefone: this.contato.telefone, email: this.computedEmail, id_funil: this.end.idFunil} 
                ),
            })
                .then((response) => response.text())
                .then((data) => {
                    window.console.log(data);
                    this.abrirObrigado();
                    this.$refs.formulario.submit();
                });
        },

        abrirWhatsapp(){
            window.open(this.end.whats)
        },
        abrirObrigado(){
            this.$router.push('/'+this.$route.params.cidade +'/lp-video/obrigado')
            window.location.reload();

        }
    },

    mounted() {
        window.scrollTo({
            top: 50,
            left: 0,
            behavior: "smooth",
        });

        let city = this.$route.params.cidade
        if (city == "sao-jose-dos-campos"){
            this.end = {
                iframe: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3667.5089012140484!2d-45.8851622!3d-23.1881175!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94cc4a15f76c83cf%3A0xf521eb334f265620!2sAv.%20Dr.%20Jo%C3%A3o%20Guilhermino%2C%20101%20-%20Centro%2C%20S%C3%A3o%20Jos%C3%A9%20dos%20Campos%20-%20SP%2C%2012210-131!5e0!3m2!1spt-BR!2sbr!4v1660910694402!5m2!1spt-BR!2sbr",
                 goggle: "https://www.google.com/maps/place/Av.+Dr.+Jo%C3%A3o+Guilhermino,+101+-+Centro,+S%C3%A3o+Jos%C3%A9+dos+Campos+-+SP,+12210-131/@-23.1881175,-45.8851622,17z/data=!3m1!4b1!4m5!3m4!1s0x94cc4a15f76c83cf:0xf521eb334f265620!8m2!3d-23.1881175!4d-45.8851622",
                 whats: "https://api.whatsapp.com/send?phone=551230420948",
                 id: 99,
                 form:"form_sjc",
                 idFunil: "62fd41aac657610011b28e68"      
           } 
        }
        
         if (city == "sorocaba") {
             this.end = { 
                 iframe: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3658.8703558087204!2d-47.4618539!3d-23.5011787!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94c58abc5cc6cc91%3A0x60bf2b917ee87186!2sR.%20da%20Penha%2C%20835%20-%20Centro%2C%20Sorocaba%20-%20SP%2C%2018010-003!5e0!3m2!1spt-BR!2sbr!4v1660910831690!5m2!1spt-BR!2sbr",
                 goggle: "https://www.google.com/maps/place/R.+da+Penha,+835+-+Centro,+Sorocaba+-+SP,+18010-003/@-23.5011787,-47.4618539,17z/data=!3m1!4b1!4m5!3m4!1s0x94c58abc5cc6cc91:0x60bf2b917ee87186!8m2!3d-23.5011787!4d-47.4618539",
                 whats: "https://api.whatsapp.com/send?phone=551531410960",
                 id: 100,
                 form:"form_sorocaba",
                 idFunil: "62ffa7a795d1490010a65133"
           }
         }
    },

    computed: {
        computedEmail() {
            return `${this.contato.telefone
                .replace("-", "")
                .replace("(", "")
                .replace(")", "")
                .replaceAll(" ", "")}@${this.contato.nome.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replaceAll(" ", "").toLowerCase()}.com.br`;
        },
        isHome() {
            return this.$route.name == "inicial";
        },
        intBreakPoint() {
            switch (this.$vuetify.breakpoint.name) {
                case "xs":
                    return 0;
                case "sm":
                    return 1;
                case "md":
                    return 2;
                case "lg":
                    return 3;
                case "xl":
                    return 4;
                default:
                    return 0;
            }
        },
    },

}
</script>

<style scoped>
@media (min-device-width: 360px) and (max-device-width: 640px) {

    .bgc1 {
        padding: 62px 0px !important;
    }
    .pdBtn{
        padding: 0px 50px !important;
    }

    .pdlfBtn{
        padding-left:90px !important;
    }
    .wdLogo{
        max-width: 170px !important
    }

    .paddingMobiPt1 {
        padding: 80px 10px;
    }

    .sizeMobile {
        width: 200px !important;
    }

    .disabledMobile {
        display: none !important;
    }

    .heightMob {
        height: auto !important;
    }

    .padMobile {
        padding: 20px 10px !important;
    }

    .padCardMob {
        padding: 10px !important;
    }

    .disabledDesk {
        display: initial !important;
    }
}

.disabledDesk {
    display: none;
}

.bgc2 {
    background-image: url("../assets/bannerCartao2.webp") !important;
    background-repeat: repeat;
    background-size: cover;
    /* background-attachment: fixed; */
    background-position: center;
}
.bgcDialog {
    background-image: url("../assets/whatsapp.png") !important;
    background-repeat: repeat;
    background-size: cover;
    /* background-attachment: fixed; */
    background-position: center;
}

.bgc1 {
    background-image: url("../assets/bannerInicial.webp") !important;
    background-repeat: repeat;
    background-size: cover;
    height: 100vh;
    /* background-attachment: fixed; */
    background-position: center;
}
</style>