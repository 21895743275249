<template>
    <v-container fluid>

        <v-app-bar app color="#EF7C00" dark v-if="!isHome" jutify="center" align="center"
            style="padding: 4px 60px; height: 100px; background-color: #EF7C00; "
            class="justify-center alignMobile d-flex flex-column padMobile">
            <v-row style="width: 100%" align="center">
                <v-col cols="12" md="12">
                    <div class="d-flex align-center justify-center">
                        <router-link :to="'/' + $route.params.cidade + '/empresarial'" class="d-flex justify-center">
                            <v-img alt="Vuetify Name" class="shrink mt-1 mobileSize" contain min-width="50%"
                                src="../assets/logoBranco.webp" width="50%" />
                        </router-link>
                    </div>
                </v-col>
            </v-row>
        </v-app-bar>

        <v-row style="background-color: #006633; height: 60px;"></v-row>

        <v-row class="justify-center align-center bgc1 heightMob padMob" style="padding: 0 40px;">
            <v-col cols="12" md="6">
                <p class="title1"
                    style="margin-bottom: 0px !important; font-size: 45px; font-family: GPMetro light; color: #fff; line-height: 1;">
                    Agilidade e economia na <br> proteção à saúde dos seus <br> <span
                        style="font-size: 81px; color: #EF7C00;" class="title2">colaboradores.</span></p>
               
                    <v-btn @click="dialog = true" class="btn1Mob" style="margin-top: 20px; background-color: #EF7C00; color: #fff; box-shadow: none; font-size: 30px; font-family: 'GPMetro Light';
                    text-transform: initial; padding: 30px;">Quero saber mais!</v-btn>
                
            </v-col>

            <v-col cols="12" md="6" class="d-flex flex-column" style="align-items: end; padding: 0px">
            </v-col>
        </v-row>

        <v-row class="bgc2 padMobile padMob" style="padding: 20px 60px;">
            <v-col cols="12" md="12" class="alinhadoM">
                <h1 style="font-family: GPMetro light; color: #EF7C00; font-size: 35px;">Benefícios</h1>
            </v-col>
            <v-col cols="6" md="3">
                <div style="background-color: #f6a300db; border-radius: 8px; padding: 20px; height: 300px; min-height: 300px;"
                    class="padCardMob align-center justify-center d-flex flex-column">
                    <img src="../assets/icon1.webp" contain width="100" />
                    <p
                        style="min-height:90px; text-align: center; margin-top: 20px; margin-bottom: 0px !important; font-family: GPMetro light; color: #fff; font-size: 20px;">
                        Consultas a partir de <span style="color: #fff"> R$20,00 </span></p>
                </div>
            </v-col>

            <v-col cols="6" md="3">
                <div style="background-color: #f6a300db; border-radius: 8px; padding: 20px; height: 300px; min-height: 300px;"
                    class="padCardMob align-center justify-center d-flex flex-column">
                    <img src="../assets/70Desc.webp" contain width="100" />
                    <p
                        style="text-align: center; margin-top: 20px; margin-bottom: 0px !important; font-family: GPMetro light; color: #fff; font-size: 20px;">
                        Até <span style="color: #fff"> 70% </span> de desconto em
                        exames <span style="color: #fff"> laboratoriais </span> e
                        de <span style="color: #fff"> imagem</span>
                    </p>
                </div>
            </v-col>

            <v-col cols="6" md="3">
                <div style="background-color: #f6a300db; border-radius: 8px; padding: 20px; height: 300px; min-height: 300px;"
                    class="padCardMob align-center justify-center d-flex flex-column">
                    <img src="../assets/icon3.webp" contain width="100" />
                    <p
                        style="text-align: center; margin-top: 20px; margin-bottom: 0px !important; font-family: GPMetro light; color: #fff; font-size: 20px;">
                        Procedimentos
                        <span style="color: #fff"> odontológicos </span> com
                        até <span style="color: #fff"> 50% </span> de desconto
                    </p>
                </div>
            </v-col>

            <v-col cols="6" md="3">
                <div style="background-color: #f6a300db; border-radius: 8px; padding: 20px; height: 300px; min-height: 300px;"
                    class="padCardMob align-center justify-center d-flex flex-column">
                    <img src="../assets/icon4.webp" contain width="100" />
                    <p
                        style="text-align: center; margin-top: 20px; margin-bottom: 0px !important; font-family: GPMetro light; color: #fff; font-size: 20px;">
                        Adquira seu cartão por pouco mais de <span style="color: #fff"> R$1,00 por dia </span>
                    </p>
                </div>
            </v-col>

            <v-col cols="12" md="12" class="alinhadoM">
               
                <v-btn @click="dialog = true" style="margin-top: 20px; background-color: #EF7C00; color: #fff; box-shadow: none;" class="wdBtn">Fale com um consultor, agora mesmo!</v-btn>
                    
            </v-col>
        </v-row>

        <v-row class="fundao padMob">
            <v-col cols="12" md="12" class="alinhadoM">
                <v-img src="../assets/cartaozin.webp" contain width="350"></v-img>
            </v-col>
        </v-row>

        <v-row class="fundaoSemlinha padTopMob padMob" style="padding: 50px 0;">
            <v-col cols="12" md="12" class="alinhadoM">
                <h1 class="title2" style="font-family: GPMetro light; color: #fff; font-size: 45px;">Vantagens do Cartão
                    Beneficiar:
                </h1>
            </v-col>
            <v-col cols="12" md="12" class="alinhadoM">
                <div class="d-flex" style="margin: 10px 0 ;">
                    <img src="../assets/check.webp" width="30" height="30" style="margin: 0 20px">
                    <p class="textLeftMob"
                        style="text-align: center; margin-bottom: 0px !important; font-family: GPMetro light; color: #fff; font-size: 20px;">
                        Menor rotatividade de funcionários
                    </p>
                </div>
                <div class="d-flex" style="margin: 10px 0 ;">
                    <img src="../assets/check.webp" width="30" height="30" style="margin: 0 20px">
                    <p class="textLeftMob"
                        style="text-align: center; margin-bottom: 0px !important; font-family: GPMetro light; color: #fff; font-size: 20px;">
                        Proporciona alta performance da equipe
                    </p>
                </div>
                <div class="d-flex" style="margin: 10px 0 ;">
                    <img src="../assets/check.webp" width="30" height="30" style="margin: 0 20px">
                    <p class="textLeftMob"
                        style="text-align: center; margin-bottom: 0px !important; font-family: GPMetro light; color: #fff; font-size: 20px;">
                        Sua melhor opção entre o SUS e o plano de saúde
                    </p>
                </div>
                <div class="d-flex" style="margin: 10px 0 ;">
                    <img src="../assets/check.webp" width="30" height="30" style="margin: 0 20px">
                    <p class="textLeftMob"
                        style="text-align: center; margin-bottom: 0px !important; font-family: GPMetro light; color: #fff; font-size: 20px;">
                        Redução no número de atestados médicos desnecessários
                    </p>
                </div>
                <div class="d-flex" style="margin: 10px 0 ;">
                    <img src="../assets/check.webp" width="30" height="30" style="margin: 0 20px">
                    <p class="textLeftMob"
                        style="text-align: center; margin-bottom: 0px !important; font-family: GPMetro light; color: #fff; font-size: 20px;">
                        Seguro, Assistência/Auxílio Funeral
                    </p>
                </div>
                <div class="d-flex" style="margin: 10px 0 ;">
                    <img src="../assets/check.webp" width="30" height="30" style="margin: 0 20px">
                    <p class="textLeftMob"
                        style="text-align: center; margin-bottom: 0px !important; font-family: GPMetro light; color: #fff; font-size: 20px;">
                        Familiar com cobertura nacional.
                    </p>
                </div>
            </v-col>
        </v-row>

        <v-row class="padMob" style="background-color: #EF7C00; padding: 30px 60px" justify="center" align="center">
            <p
                style="text-align: center; margin-bottom: 0px !important; font-family: GPMetro light; color: #fff; font-size: 20px;">
                Todos os funcionários, independente do tamanho da empresa, <br>
                querem se sentir valorizados e ter acesso à saúde investindo pouco.
            </p>
        </v-row>

        <v-row class="fundaoSemlinha padMob" style="padding: 50px 60px;">
            <v-col cols="12" md="12" class="alinhadoM">
                <h1 class="text2"
                    style="font-family: GPMetro light; color: #fff; font-size: 45px; text-align: center; line-height: 1;">
                    Em nossas clínicas parceiras é <br> possível encontrar:
                </h1>
            </v-col>
            <v-col cols="12" md="12" class="mt-10">
                <v-row>
                    <v-col cols="12" md="6">
                        <div style="border: 1px solid #EF7C00; border-radius: 3px; padding: 20px">
                            <p
                                style="text-align: center; margin-bottom: 0px !important; font-family: GPMetro light; color: #fff; font-size: 20px;">
                                Ambiente acolhedor sem longas filas de espera
                            </p>
                        </div>
                    </v-col>
                    <v-col cols="12" md="6">
                        <div style="border: 1px solid #EF7C00; border-radius: 3px; padding: 20px">
                            <p
                                style="text-align: center; margin-bottom: 0px !important; font-family: GPMetro light; color: #fff; font-size: 20px;">
                                Consultas com especialidades médicas
                            </p>
                        </div>
                    </v-col>
                    <v-col cols="12" md="6">
                        <div style="border: 1px solid #EF7C00; border-radius: 3px; padding: 20px">
                            <p
                                style="text-align: center; margin-bottom: 0px !important; font-family: GPMetro light; color: #fff; font-size: 20px;">
                                Sua melhor opção entre o SUS e o plano de saúde
                            </p>
                        </div>
                    </v-col>
                    <v-col cols="12" md="6">
                        <div style="border: 1px solid #EF7C00; border-radius: 3px; padding: 20px">
                            <p
                                style="text-align: center; margin-bottom: 0px !important; font-family: GPMetro light; color: #fff; font-size: 20px;">
                                Redução no número de atestados médicos desnecessários
                            </p>
                        </div>
                    </v-col>
                    <v-col cols="12" md="12">
                        <div style="border: 1px solid #EF7C00; border-radius: 3px; padding: 20px">

                            <p
                                style="text-align: center; margin-bottom: 0px !important; font-family: GPMetro light; color: #fff; font-size: 20px;">
                                Seguro, Assistência/Auxílio Funeral familiar com cobertura nacional.
                            </p>
                        </div>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>

        <v-row class="paddigMobile fundoFAQ padMob" style="padding: 40px 80px" justify="center">
            <v-col cols="12" md="12" class="alinhadoM">
                <h1 class=""
                    style="font-family: GPMetro medium; color: #006633; line-height: 1; font-size: 38px; padding: 10px 0px; font-weight: 500;">
                    Não vá embora com dúvidas
                </h1>
            </v-col>
            <v-col cols="12" md="12">
                <v-expansion-panels accordion style="background-color: transparent !important;">
                    <v-expansion-panel v-for="(quest, i) in perguntas" :key="i">
                        <v-expansion-panel-header style="font-family: GPMetro light;">{{ quest.question }}
                        </v-expansion-panel-header>
                        <v-expansion-panel-content style="font-family: GPMetro light;">
                            {{ quest.resposta }}
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
            </v-col>
        </v-row>

        <v-row class="padMob" justify="center" align="center">
            <a style=" position: absolute; justify-content: center;"
                :href="end.goggle"
                target="_blank">
                <v-btn style="z-index: 99; align-items: center; background-color: #EF7C00; color: #fff; box-shadow: none; font-size: 25px; font-family: 'GPMetro Light';
                    text-transform: initial; padding: 25px;">Como chegar</v-btn>
            </a>
            <v-col cols="12" md="12">
                <div class="alturaFooterGreen"
                    style="background-color: #007845; opacity: 0.6; position: absolute; height: 330px; left: 0px; right: 0px; bottom: 0px;">
                </div>
                <iframe
                    :src="end.iframe"
                    width="100%" height="300" style="border:0;" allowfullscreen="" loading="lazy"
                    referrerpolicy="no-referrer-when-downgrade"></iframe>
            </v-col>
        </v-row>

        <v-dialog v-model="dialog" max-width="800" >
            <v-card class="bgcDialog" style="margin:0px !important; padding: 5px !important; height: 100% !important" >
            <v-row style="background-color:#09564C; padding:20px 20px; margin:10px; border-radius:10px">
                <v-col cols="6" md="6" class="d-flex">
                    <v-img contain style="max-width:200px" class="wdLogo" src="../assets/logoBranco.webp"></v-img>
                    
                </v-col>
                <v-col cols="6" md="6" class="pdlfBtn" style="padding-left:320px">
                    <v-btn  icon @click="dialog = false">
                    <v-icon style="color:#fff">
                        mdi-close
                    </v-icon>
                </v-btn>
                </v-col>
            </v-row>
            <v-row style="margin:10px">
                <v-col cols="12" md="8" style="background-color:#fff; margin:0px; border-radius:20px">
                    <p style="font-size:17px; color: 800; margin-bottom:0px ">
                    Olá, complete os campos abaixo e entre em contato conosco!
                    </p>
                </v-col>
            </v-row>
            <v-row style="margin:10px">
                <v-col cols="12" md="6"></v-col>
                <v-col cols="12" md="6" class="justify-center d-flex flex-column">
                    <form :id="end.form" ref="formulario" action="/empresarial/obrigado">
                    <v-text-field height="50px" background-color="#E7FFE7" placeholder="Nome completo" v-model="contato.nome" outlined style="border-radius:5px;"></v-text-field>
                    <input type="text" required placeholder="E-mail" hidden name="email" v-model="computedEmail" />
                    <v-text-field height="50px" background-color="#E7FFE7" v-mask="'(##) #####-####'" placeholder="Whatsapp" v-model="contato.telefone" outlined style="border-radius:5px"></v-text-field>
                    <button class="pdBtn" type="submit" @click="enviarFormulario" style="background-color:#09564C;  padding:0px 100px; color:#fff; font-weight:800; text-transform:none; font-size:18px">Iniciar atendimento</button>
                    </form>
                </v-col>
            </v-row>
        </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
export default {
    name: "App",
    data: () => ({
        end:{},
        dialog: false,
        contato: { nome: "", telefone: "" },
        perguntas: [
            {
                question: 'Do que se trata o Cartão Beneficiar?',
                resposta: 'O Cartão Beneficiar é um cartão de benefícios com um valor acessível e, com ele, você e sua família terão acesso a consultas médicas, exames laboratoriais, dentistas, seguro, assistência/auxílio funeral familiar, descontos no comércio e muito mais'
            },
            {
                question: 'Quais serviços tenho disponíveis com o Cartão?',
                resposta: 'Consultas médicas diversas em redes credenciadas. Consultas odontológicas em redes credenciadas. Exames em redes credenciadas. Descontos em serviços credenciados: clínicas veterinárias, farmácias, pet shoppings e etc. Descontos no comércio em redes credenciadas: salão de beleza, escolas, ótica, estacionamentos, entre outros. 😱 💚'
            },
            {
                question: 'O valor é o mesmo para pessoas mais velhas?',
                resposta: 'Sim. Todos são atendidos com os mesmos benefícios, sem diferença de valores.'
            },
            {
                question: 'Os benefícios são para quais membros da família?',
                resposta: 'Os benefícios se aplicam para o titular do cartão, o cônjuge (marido ou esposa) e filhos até 21 anos.'
            },
            {
                question: 'É obrigatório a contratação da assistência/auxílio funeral?',
                resposta: 'Não. A contratação do seguro é opcional e não influencia na contratação dos demais benefícios.'
            },
            {
                question: 'O que contempla o seguro e assistência funeral?',
                resposta: 'Cobertura por morte acidental de R$ 3.500,00 (apenas para titulares); Seguro opcional com baixo custo para os demais dependentes que não se enquadrem como dependentes legais.'
            },
            {
                question: 'Como funciona a carência do Cartão?',
                resposta: 'Não tem carência. Ao adquirir o cartão Beneficiar, você já pode aproveitar todos os benefícios no mesmo dia e as cobranças são feitas no próprio local (clínica ou laboratórios).'
            },
            {
                question: 'Como funciona o cancelamento do Cartão?',
                resposta: 'O contrato de adesão tem validade de 18 meses. Ao término desse período, o cartão poderá ser rescindido a qualquer momento sem multa. O processo de cancelamento é bem simples e sem complicações, basta ir até a unidade mais próxima e realizar a solicitação.'
            }
        ],
    }),

    mounted() {
        window.scrollTo({
            top: 50,
            left: 0,
            behavior: "smooth",
        });

        let city = this.$route.params.cidade
        if (city == "sao-jose-dos-campos"){
            this.end = {
                iframe: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3667.5089012140484!2d-45.8851622!3d-23.1881175!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94cc4a15f76c83cf%3A0xf521eb334f265620!2sAv.%20Dr.%20Jo%C3%A3o%20Guilhermino%2C%20101%20-%20Centro%2C%20S%C3%A3o%20Jos%C3%A9%20dos%20Campos%20-%20SP%2C%2012210-131!5e0!3m2!1spt-BR!2sbr!4v1660910694402!5m2!1spt-BR!2sbr",
                 goggle: "https://www.google.com/maps/place/Av.+Dr.+Jo%C3%A3o+Guilhermino,+101+-+Centro,+S%C3%A3o+Jos%C3%A9+dos+Campos+-+SP,+12210-131/@-23.1881175,-45.8851622,17z/data=!3m1!4b1!4m5!3m4!1s0x94cc4a15f76c83cf:0xf521eb334f265620!8m2!3d-23.1881175!4d-45.8851622",
                 whats: "https://api.whatsapp.com/send?phone=551230420948",
                 id: 95,
                 form: "form_sjc",
                 idFunil: "62fd41aac657610011b28e68"   
           } 
        }
        
         if (city == "sorocaba") {
             this.end = { 
                 iframe: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3658.8703558087204!2d-47.4618539!3d-23.5011787!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94c58abc5cc6cc91%3A0x60bf2b917ee87186!2sR.%20da%20Penha%2C%20835%20-%20Centro%2C%20Sorocaba%20-%20SP%2C%2018010-003!5e0!3m2!1spt-BR!2sbr!4v1660910831690!5m2!1spt-BR!2sbr",
                 goggle: "https://www.google.com/maps/place/R.+da+Penha,+835+-+Centro,+Sorocaba+-+SP,+18010-003/@-23.5011787,-47.4618539,17z/data=!3m1!4b1!4m5!3m4!1s0x94c58abc5cc6cc91:0x60bf2b917ee87186!8m2!3d-23.5011787!4d-47.4618539",
                 whats: "https://api.whatsapp.com/send?phone=551531410960",
                 id: 96,
                 form: "form_sorocaba",
                 idFunil: "62ffa7a795d1490010a65133"
           }
         }
    },

    methods: {
        goto(refName) {
            var element = this.$refs[refName];
            var top = element.offsetTop;

            window.scrollTo(0, top);
        },

        enviarFormulario(e) {
            e.preventDefault()
            if ((this.contato.nome === "")) { 
                alert("Por favor preencha todos os campos")
                this.loading = false
                return;
            }
            else if ((this.contato.telefone === "")){
                alert("Por favor preencha todos os campos")
                this.loading = false
                return;
            }
            else{
                this.abrirWhatsapp();
                this.dialog = false;
            }
            fetch("https://gestao.abare.cloud/items/leads", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                },
                body: JSON.stringify({ id_formulario: this.end.id, lead: this.contato }),
            })
                .then((response) => response.text())
                .then((data) => {
                    window.console.log(data);
                    // alert("contato enviado com sucesso");
                    this.snackbar = true;
                    this.enviaRdstation();
                });
        },
        enviaRdstation() { 
            fetch("https://abaremarketing.com/phpCartaoBe.php", {
                method: "POST",
                mode: "no-cors",
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                },
                body: JSON.stringify(
                   {nome: this.contato.nome, telefone: this.contato.telefone, email: this.computedEmail, id_funil: this.end.idFunil} 
                ),
            })
                .then((response) => response.text())
                .then((data) => {
                    window.console.log(data);
                    this.abrirObrigado();
                    this.$refs.formulario.submit();
                });
        },

        abrirObrigado(){
            this.$router.push('/'+this.$route.params.cidade +'/empresarial/obrigado')
            window.location.reload();

        },

        abrirWhatsapp(){
            window.open(this.end.whats)
        }
    },

    computed: {
        computedEmail() {
            return `${this.contato.telefone
                .replace("-", "")
                .replace("(", "")
                .replace(")", "")
                .replaceAll(" ", "")}@${this.contato.nome.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replaceAll(" ", "").toLowerCase()}.com.br`;
        },
        isHome() {
            return this.$route.name == "inicial";
        },
        intBreakPoint() {
            switch (this.$vuetify.breakpoint.name) {
                case "xs":
                    return 0;
                case "sm":
                    return 1;
                case "md":
                    return 2;
                case "lg":
                    return 3;
                case "xl":
                    return 4;
                default:
                    return 0;
            }
        },
    },

}
</script>

<style scoped>
@media (min-device-width: 360px) and (max-device-width: 640px) {
    .alturaFooterGreen {
        height: 370px !important;
    }
    .pdBtn{
        padding: 0px 50px !important;
    }
    .wdBtn{
        font-size:13px !important;
    }

    .pdlfBtn{
        padding-left:90px !important;
    }
    .wdLogo{
        max-width: 170px !important
    }

    .fundoFAQ {
        background-image: url("../assets/bannerFaq.webp") !important;
        height: auto !important;
    }

    .padMob {
        padding: 20px 10px !important;
    }

    .textLeftMob {
        text-align: left !important;
    }

    .padTopMob {
        padding: 20px 0 !important;
    }

    .heightMob {
        height: auto !important;
        padding: 190px 10px 50px 0 !important;
    }

    .padMobile {
        padding: 20px 10px !important;
    }

    .padCardMob {
        padding: 10px !important;
    }

    .title1 {
        font-size: 33px !important;
    }

    .title2 {
        font-size: 40px !important;
    }

    .btn1Mob {
        font-size: 22px !important;
        padding: 20px !important;
    }
}

.bgc1 {

    background-image: url("../assets/bannerEmpr.webp") !important;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
    background-position: center;
}

.fundoFAQ {
    background-image: url("../assets/bannerFaq.webp") !important;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
    background-position: center;
}

.fundaoSemlinha {
    background-image: url("../assets/fundao.webp") !important;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.bgcDialog {
    background-image: url("../assets/whatsapp.png") !important;
    background-repeat: repeat;
    background-size: cover;
    /* background-attachment: fixed; */
    background-position: center;
}

.fundao {
    background-image: url("../assets/fundaoCartaoBen.webp") !important;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
    background-position: center;
}

.bgc2 {
    background-image: url("../assets/fundaoCards.webp") !important;
    background-repeat: repeat;
    background-size: cover;
    /* background-attachment: fixed; */
    background-position: center;
}
p span { 
    font-weight:bold;
}
</style>